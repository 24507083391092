<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="dialogStatus"
      persistent
      content-class="warranty-dialog"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
        >
          <template v-if="lodash.isEmpty(prventiveDetail) === false">
            Extend Preventive Maintenance ({{ prventiveDetail?.barcode }})
          </template>
          <template v-else>
            Extend Preventive Maintenance ({{
              prventiveDetail?.barcode
            }})</template
          >
        </v-card-title>
        <v-card-text class="p-8 font-size-16">
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll"
            style="max-height: 90vh; position: relative"
          >
            <v-form
              ref="warrantyForm"
              v-model.trim="formValid"
              lazy-validation
              v-on:submit.stop.prevent="update_or_create"
            >
              <v-container fluid>
                <v-row>
                  <v-col md="4" class="py-0">
                    <label class="font-weight-600 ml-1 required"
                      >Duration</label
                    >
                    <v-text-field
                      type="number"
                      filled
                      label="Duration"
                      solo
                      flat
                      color="cyan"
                      :rules="[
                        validateRules.required(extendPM.duration, 'duration.'),
                      ]"
                      class="pr-2 width-100"
                      dense
                      v-model.trim="extendPM.duration"
                      v-mask="'###'"
                      v-on:keyup="updatePMDuration()"
                      :min="1"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" class="py-0">
                    <label class="font-weight-600 ml-1">Duration Type</label>
                    <v-select
                      :items="warrantyDurationTypes"
                      v-model.trim="extendPM.duration_type"
                      dense
                      filled
                      solo
                      flat
                      class="pl-2 pr-2 width-100"
                      label="Duration Type"
                      color="cyan"
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      v-on:change="updatePMDuration()"
                    ></v-select>
                  </v-col>
                  <v-col md="4" class="py-0">
                    <label class="font-weight-600 ml-1">Extend Date</label>
                    <div class="font-weight-600 ml-1" style="font-size: 15px">
                      {{ formatDate(extendPM.extend_date) }}
                    </div>
                  </v-col>

                  <v-col md="12" class="py-0">
                    <label class="font-weight-600 ml-1">Remark</label>
                    <v-textarea
                      v-model.trim="extendPM.remark"
                      auto-grow
                      dense
                      filled
                      solo
                      flat
                      color="cyan"
                      label="remark"
                      row-height="20"
                      v-on:keypress="(e) => manageLimit(e)"
                      v-on:paste="(e) => onPaste(e)"
                    ></v-textarea>
                    <div class="text-right">
                      {{ extendPM.remark ? extendPM.remark.length : 0 }}/200
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </perfect-scrollbar>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="warrant-manage-action">
            <v-btn
              :disabled="!formValid || pageLoading"
              :loading="pageLoading"
              v-on:click="update_or_create"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              >Save
            </v-btn>
            <v-btn
              :loading="pageLoading"
              :disabled="pageLoading"
              v-on:click="$emit('close:dialog')"
              class="mx-2 custom-grey-border custom-bold-button"
              >Cancel
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 18px;
  padding: 0;
}
.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
}
</style>

<script>
/*   import DatePicker from "@/view/pages/partials/Datepicker.vue"; */
import {
  /* , PUT, CLEAR_ERROR , */ /* GET */
  POST,
  CLEAR_ERROR,
  /* QUERY, */
} from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { ErrorEventBus } from "@/core/lib/message.lib";

/*   import Barcode from "@/view/pages/partials/Barcode.vue"; */
import moment from "moment";
import { toSafeInteger } from "lodash";
export default {
  name: "create-or-update-warranty",
  mixins: [ValidationMixin, CommonMixin],
  props: {
    dialogStatus: {
      type: Boolean,
      default: false,
    },

    prventiveDetail: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  components: {
    /* DatePicker, */
    /*   Barcode, */
  },
  watch: {
    prventiveDetail: {
      deep: true,
      immediate: true,
      handler() {
        if (this.lodash.isEmpty(this.prventiveDetail) === false) {
          this.initialDate = moment(
            this.prventiveDetail.end_date,
            "YYYY-MM-DD"
          );
          this.extendPM.extend_date = moment(
            this.prventiveDetail.end_date,
            "YYYY-MM-DD"
          );
        }
      },
    },
    dialogStatus: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.updatePMDuration();
        }
      },
    },
  },
  data() {
    return {
      equipmentInfo: {},
      customerId: 0,
      locationId: 0,
      allSiteLocationList: [],
      formValid: true,
      initialDate: null,
      pageLoading: false,
      equipmemntArr: {},
      startDatePicker: null,
      defaultItemWarranty: new Array(),
      /*    allEquipemtList: [],
        allCustomerList: [], */
      barcode: null,
      files: [
        {
          file: null,
          name: null,
        },
      ],
      extendPM: new Object({
        extend_date: null,
        duration: 1,
        duration_type: "month",
      }),
      warrantyList: [
        { text: "30 Days", value: "30", type: "day" },
        { text: "6 Months", value: "182", type: "day" },
        { text: "1 Year", value: "365", type: "day" },
        { text: "2 Years", value: "730", type: "day" },
        { text: "3 Years", value: "1095", type: "day" },
      ],
      warrantyDurationTypes: [
        {
          text: "Day(s)",
          value: "day",
        },
        {
          text: "Month(s)",
          value: "month",
        },
        {
          text: "Year(s)",
          value: "year",
        },
      ],
    };
  },
  methods: {
    /* get_formatted_property(row) {
        let tempArr = [];
        if (row.unit_no) {
          tempArr.push(row.unit_no);
        }
        if (row.street_1) {
          tempArr.push(row.street_1);
        }
        if (row.street_2) {
          tempArr.push(row.street_2);
        }
        if (row.zip_code) {
          tempArr.push(row.zip_code);
        }
        if (row.country_name) {
          tempArr.push(row.country_name);
        }
        return tempArr.join(", ");
      }, */
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.extendPM.remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.extendPM.remark = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (this.extendPM.remark && this.extendPM.remark.length > 199) {
        e.preventDefault();
      }
    },

    updatePMDuration() {
      let warranty_extend_date = this.initialDate;
      if (warranty_extend_date) {
        let date = moment(warranty_extend_date, "YYYY-MM-DD");

        let duration = this.extendPM.duration
          ? toSafeInteger(this.extendPM.duration)
          : 0;

        switch (this.extendPM.duration_type) {
          case "day":
            console.log({ duration });

            date = moment(warranty_extend_date, "YYYY-MM-DD").add(
              duration,
              "d"
            );
            break;
          case "month":
            date = moment(warranty_extend_date, "YYYY-MM-DD").add(
              duration,
              "M"
            );
            break;
          case "year":
            date = moment(warranty_extend_date, "YYYY-MM-DD").add(
              duration,
              "y"
            );
            break;
        }
        this.extendPM.extend_date = date.format("YYYY-MM-DD");
      }
    },

    update_or_create() {
      const _this = this;
      if (!_this.$refs.warrantyForm.validate()) {
        return false;
      }
      _this.$store.dispatch(CLEAR_ERROR, {});

      if (Number(this.extendPM.duration) == 0) {
        ErrorEventBus.$emit(
          "update:error",
          "Duration cannot be zero. Please enter a valid duration."
        );
        return false;
      }
      _this.pageLoading = true;
      let formData = new FormData();

      formData.append(
        "duration_type",
        this.extendPM.duration_type ? this.extendPM.duration_type : "day"
      );
      /* if (this.extendPM && this.extendPM.duration) { */
      formData.append(
        "duration",
        this.extendPM.duration ? this.extendPM.duration : 0
      );
      /*   } */
      if (this.extendPM && this.extendPM.extend_date) {
        formData.append(
          "extend_date",
          this.extendPM.extend_date ? this.extendPM.extend_date : null
        );
      }
      if (this.extendPM && this.extendPM.remark) {
        formData.append(
          "remark",
          this.extendPM.remark ? this.extendPM.remark : null
        );
      }

      /*  for (let i = 0; i < this.files.length; i++) {
          if (this.files && this.files[i] && this.files[i].file) {
            formData.append(`file[${i}][file]`, this.files[i].file);
            formData.append(`file[${i}][name]`, this.files[i].name);
          }
        }
   */
      let requestTYPE = POST;
      let requestURL = `preventive-maintanance-extend/${this.prventiveDetail.id}`;
      this.$store
        .dispatch(requestTYPE, {
          url: requestURL,
          data: formData,
        })
        .then(() => {
          _this.$emit("update:preventive", true);
          _this.$emit("close:dialog");
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    updateFile(index, file, value, data) {
      if (value == "keyword") {
        if (file && file.name) {
          data[index].name = file.name.split(".").slice(0, -1).join(".");
          data[index].suffix = `.${file.name.split(".").pop()}`;
        } else {
          data[index].name = null;
          data[index].suffix = null;
        }
      } else {
        if (file && file.name) {
          this.files[index].name = file.name.split(".").slice(0, -1).join(".");
          this.files[index].suffix = `.${file.name.split(".").pop()}`;
        } else {
          this.files[index].name = null;
          this.files[index].suffix = null;
        }
      }
    },
    /*   getEquipment(id) {
        if (id && id > 0) {
          const _this = this;
          _this.$store
            .dispatch(QUERY, { url: `equipment/${id}` })
            .then(({ data }) => {
              this.equipmentInfo = data;
              this.equipmemntArr = data.property;
            })
            .catch((error) => {
              _this.logError(error);
            });
        }
      }, */
    addMore() {
      this.files.push({
        file: null,
        name: null,
      });
    },
    /*   getAllCustomers() {
        const _this = this;
        _this.$store
          .dispatch(GET, { url: "customer-list" })
          .then(({ data }) => {
            _this.allCustomerList = data;
          })
          .catch((error) => {
            _this.logError(error);
          });
      },
      getCustomerEquipment() {
        const _this = this;
        _this.$store
          .dispatch(GET, { url: "equipment-list" })
          .then(({ data }) => {
            _this.allEquipemtList = data;
          })
          .catch((error) => {
            _this.logError(error);
          });
      }, */

    removeFile(index) {
      this.files.splice(index, 1);
      if (this.files.length < 1) {
        this.addMore();
      }
    },
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
